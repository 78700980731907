import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import { getListParticipant } from "../../apiDefault/participants";
import Address from "../../Components/Address";
import Number from "../../Components/Number";
import Title from "../../Components/Title";
import WrapperFlex from "../../Components/WrapperFlex";
import IconEmployees from "../../images/icon-employees.svg";
import IconSales from "../../images/icon-sales.svg";
import NotFound from "../404/404";

const CvWrapper = styled.section`
  position: fixed;
  background-color: #fff;
  z-index: 1000;
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 100vh;
  @media (max-width: 767px) {
    position: absolute;
    flex-direction: column;
    height: auto;
    min-height: 250vh;
  }
  @media (min-width: 1935px) {
    max-width: 1935px;
    margin: auto;
  }
`;

const Side = styled.div`
  border-left: 1px solid #e6e6e6;
  border-right: 1px solid #e6e6e6;

  @media (max-width: 767px) {
    order: ${(props) => props.order};
    padding: 5vh;
    border: none;
    border-bottom: 1px solid #e6e6e6;
    min-height: 100vh;
    overflow: hidden;
    height: auto;
  }
  height: 100vh;
  overflow: scroll;
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10vh;
`;

const Article = styled.article`
  position: relative;
  text-align: justify;
  font-family: Montserrat, sans-serif;
  font-size: 15px;
  line-height: 22px;
  max-height: 50%;
  margin-top: 10vh;
`;

const Img = styled.img`
  border: 1px solid yellow;
  height: 175px;
  width: 150px;
  object-fit: cover;
  object-position: center;
  filter: grayscale(100%);
`;

const RedBar = styled.div`
  margin: 1vh 0 2vh 0;
  height: 1.2vh;
  width: 50%;
  background-color: #d12834;
`;

const Close = styled.a`
  position: absolute;
  right: 0;
  padding: 3vh;
  cursor: pointer;
  z-index: 1000;
  @media (max-width: 767px) {
    padding: 1.5vh;
  }
`;

const getMemberId = (name, members) => {
  let memberId = null;
  if (name.indexOf("_") > 0) {
    let fullName =
      name.toLowerCase().indexOf(".html") > 0
        ? decodeURI(name).toLowerCase().split(".html")[0].split("_")
        : decodeURI(name).split("_");

    members?.forEach((member) => {
      if (
        fullName[0].toLowerCase() === member?.name.toLowerCase() &&
        fullName[1].toLowerCase() === member.surname.toLowerCase()
      ) {
        memberId = member.id;
      }
    });
  }
  return memberId ? memberId : null;
};

export default function Participant() {
  const [members, setMembers] = useState();
  const [member, setMember] = useState();
  const getParticipants = async () => {
    const participants = await getListParticipant();
    setMembers(participants);
  };

  const { name } = useParams();
  const history = useHistory();

  useEffect(() => {
    if (members) {
      let id = getMemberId(name, members);
      if (id) {
        setMember(members.filter((member) => member.id === id)[0]);
      }
    }
  }, [name, members]);

  useEffect(() => {
    getParticipants();
  }, []);

  if (!member || typeof member === "undefined") {
    return <NotFound />;
  }

  const onClick = (e) => {
    e.preventDefault();

    if (history.length > 2) {
      history.goBack();
    } else {
      history.push("/club");
    }
  };

  return (
    <CvWrapper>
      <Close onClick={onClick}>
        <i
          style={{ color: "rgba(126, 126, 126)", fontSize: "30px" }}
          className="fas fa-times"
        ></i>
      </Close>

      <Side order={1}>
        <WrapperFlex direction="row" position="space-between">
          <WrapperFlex
            direction="column"
            position="start"
            mb="10vh"
            maxWidth="60%"
          >
            <Title h="h1" size="4vmin">
              {member?.name}
            </Title>
            <Title h="h1" size="4vmin">
              {member?.surname}
            </Title>
            <RedBar />
            <Title h="h2" size="2vmin">
              {member?.position}
            </Title>
            <Title h="h2" size="2vmin">
              {member?.company?.name}
            </Title>
          </WrapperFlex>
          <Img src={process.env.REACT_APP_API_URL + "" + member.picture.url} />
        </WrapperFlex>

        <Article>{member.cv}</Article>
      </Side>
      <Side order={2}>
        <WrapperFlex direction="row" position="center">
          <Title h="h1" size="4vh" align="center" maxWidth>
            {member?.company?.name}
          </Title>
          <Address>{member?.company?.address}</Address>
        </WrapperFlex>
        <WrapperFlex direction="column" position="center" mt="2vh">
          {member?.company?.sales && (
            <Number
              icon={IconSales}
              number={member.company.sales + "M€"}
              text={"Chiffre d'affaires"}
            />
          )}
          {member?.company?.employees && (
            <Number
              icon={IconEmployees}
              number={member.company.employees}
              text={"Collaborateurs"}
            />
          )}
        </WrapperFlex>
        <Article>{member?.company?.description}</Article>
      </Side>
    </CvWrapper>
  );
}
