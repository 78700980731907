import axios from 'axios';
const apiEndPoint = process.env.REACT_APP_API_URL;



const getListParticipant = async () => {
  const response = await axios.get(`${apiEndPoint}/participants?_sort=surname:ASC,name:ASC`)
  return response.data;

}

export {getListParticipant};