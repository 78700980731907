import React from "react";
import { getListParticipant } from "../../apiDefault/participants";
import Card from "../../Components/Card";
import "./slider.css";


function SliderMembers() {
  const [members, setMembers] = React.useState(null);

  const getParticipants = async () => {
    const participants = await getListParticipant();
    setMembers(participants);
  };

  React.useEffect(() => {
    getParticipants();
  }, []);

  if (!members) {
    return <p>LOADING</p>;
  }

  return (
    <main className="main-slider">
      <div className="container-cards">
        {members.map((member, index) => {
          return (
              <Card key={member.id} member={member} gouv link={`/participant/${member.name}_${member.surname}`} />
          );
        })}
      </div>
    </main>
  );
}

export default SliderMembers;
