import axios from 'axios';
const apiEndPoint = process.env.REACT_APP_API_URL;



const getEvent= async () => {
  const response = await axios.get(`${apiEndPoint}/events?_sort=created_at:DESC`)
  return response.data;

}

export {getEvent};